/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageLoader } from "../../../../../../../components/PageLoader/PageLoader";
import { StandadCard } from "../../../../../../../components/Cards";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../../redux/initialStoreState";
import { LoadState, MODULE_IDS } from "../../../../../../../constants/enums";
import { useDispatchWrapper } from "../../../../../../../hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../../../../../components/formsComponents";

import produce from "immer";
import {
  addAutomobileScheduleAAsync,
  clearAutomobileScheduleState,
  fetchAutomobileScheduleAAsync,
} from "../../../../../../../redux/automobileSchedule/automobileScheduleActions";
import { RoleBasedCustomButton } from "../../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { AutoComplete } from "../../../../../../../components/AutoCompleteSearches/AutoComplete";
import {
  COMMON_STATUS,
  csioOtherCoverages,
} from "../../../../../../../constants/constants";
import { fetchQuotedefaultCoverageList } from "../../../../../../../redux/QuoteSettings/quoteSettingsActions";
import { useDefaultQuote } from "../../../../../../../hooks/useDefaultQuote";
import {
  IAutomobileScheduleA,
  IAutomobileScheduleState,
} from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";
import { useAutomobilePolicyVehcileInfo } from "../../hooks/useAutomobileVehicleInfo";

interface IAutomobileScheduleAProps {
  customerId?: number;
  customerPolicyId?: number;
  incrementalNumber?: number;
  readOnly?: boolean;
  fromRightPanel?: boolean;
  staticStatusValue?: string;
  created_from?: string;
  onSaveSuccess: (data: IAutomobileScheduleA) => void;
  initialData?: IAutomobileScheduleA;
  disableApiCalls?: boolean;
  vehicleNumber?: number;
}

export const AutomobileScheduleA: React.FC<IAutomobileScheduleAProps> = (
  props
) => {
  const {
    customerId,
    customerPolicyId,
    incrementalNumber,
    onSaveSuccess,
    fromRightPanel = false,
    readOnly = false,
    staticStatusValue,
    created_from,
    initialData,
    disableApiCalls = false,
    vehicleNumber
  } = props;

  const { data } = useSelector(
    (storeState: IStoreState) => storeState.automobile.schedule.scheduleA
  );
  const vehiclesList = useSelector(
    (storeState: IStoreState) =>
      storeState.automobile.vehicle.VehicleInformation.vehiclesList
  );

  const coverageList = useSelector(
    (state: IStoreState) => state.quoteConfig.defaultCoverage.coverageList
  );

  const dispatch = useDispatchWrapper();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const navigate = useNavigate();

  const { coverageList: defaultCoverage, fetchDefaultCoverageByCode } =
    useDefaultQuote();

  const {vehcileDropdown}  = useAutomobilePolicyVehcileInfo(customerPolicyId)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialData ? initialData : data,
    validate: (values) => {
      const errors: any = {};
      if (!values.vehicle_number) {
        errors.vehicle_number = "Vehicle is required.";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (disableApiCalls) {
        onSaveSuccess(values);
      } else {
        if (customerPolicyId && customerId) {
          let statusValue = values.status;
          if (staticStatusValue) {
            statusValue = staticStatusValue;
          }
          setSaveLoading(true);
          dispatch(
            addAutomobileScheduleAAsync(
              {
                ...values,
                customer_policy_id: customerPolicyId,
                status: statusValue,
                created_from: created_from || "Coverage",
              },
              (isSuccess, data) => {
                if (isSuccess && data) {
                  onSaveSuccess(data);
                  window.scrollTo(0, 0);
                }
                setSaveLoading(false);
              }
            )
          );
        }
      }
    },
  });

  const handleTextChange =
    (index: number, key: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_details[index][key as "key"] = e.target.value;
      });
      setValues(newValues);
    };

  const handleSelectChange =
    (index: number, key: string) => (selectedOption: any) => {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_details[index][key as "key"] = selectedOption
          ? selectedOption
          : "";
      });
      setValues(newValues);
    };

  const total = React.useMemo(() => {
    return values.coverage_details.reduce((item, currentValue) => {
      return item + (Number(currentValue.premimum) || 0);
    }, 0);
  }, [values.coverage_details]);

  const totalTwo = React.useMemo(() => {
    return values.coverage_details.reduce((item, currentValue) => {
      return item + (Number(currentValue.description) || 0);
    }, 0);
  }, [values.coverage_details]);

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber && vehicleNumber ) {
      dispatch(
        fetchAutomobileScheduleAAsync(customerPolicyId, incrementalNumber, vehicleNumber)
      );
    }
  }, []);

  React.useEffect(() => {
    dispatch(fetchQuotedefaultCoverageList());
  }, []);

  React.useEffect(() => {
    setValues({ ...data });
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAutomobileScheduleState());
    };
  }, []);

  

  const defaultCoverageList = React.useMemo(() => {
    return coverageList.map((item) => {
      return {
        label: `${item.default_coverage_name || "--"} ( ${
          item.default_coverage_code || "N/A"
        } 
        )`,
        value: item.default_coverage_code || "",
      };
    });
  }, [coverageList]);

  const handleCoverageChange = async (e: SelectChangeEvent<unknown>) => {
    const coverageCode = e.target.value as string;

    const CoverageInfo = await fetchDefaultCoverageByCode(coverageCode);
    console.log("coverage", CoverageInfo);

    if (CoverageInfo) {
      const newValues = produce(values, (draftValues) => {
        draftValues.coverage_name = coverageCode;
        draftValues.coverage_details = CoverageInfo.coverage_details;
      });
      setValues(newValues);
    }
  };

  return (
    <PageLoader loading={false}>
      <StandadCard
        renderWithoutCard={fromRightPanel}
        heading={!fromRightPanel ? "Vehicle Coverage Summary" : ""}
        rightHeading={
          !incrementalNumber && (
            <FieldSet disabled={readOnly}>
              <Stack
                direction={"row"}
                spacing={3}
                width={"100%"}
                alignItems={"center"}
              >
                <Typography
                  display={"block"}
                  variant="h6"
                  fontWeight={"bold"}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Default Coverage{" "}
                </Typography>
                <ControlledCustomSelect
                  sx={{ width: "300px" }}
                  variant="outlined"
                  size="small"
                  type="string"
                  name="coverage_name"
                  value={values.coverage_name}
                  onChange={handleCoverageChange}
                  displayEmpty
                  fullWidth
                  placeholder="Select one"
                  options={defaultCoverageList}
                ></ControlledCustomSelect>
              </Stack>
            </FieldSet>
          )
        }
      >
        <form onSubmit={handleSubmit}>
          <FieldSet disabled={readOnly}>
            <>
              <Grid container sx={{ mt: 2 }} spacing={2}>
                <Grid item xs={6} md={6}>
                  <CustomFormLabel
                    fontWeight={600}
                    variant="h4"
                    textAlign={"center"}
                  >
                    Vehicle Number
                  </CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="vehicle_number"
                    value={values.vehicle_number}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    placeholder="Select one"
                    options={vehcileDropdown}
                    helperText={errors.vehicle_number}
                    error={errors.vehicle_number ? true : false}
                  ></ControlledCustomSelect>
                </Grid>
                {!staticStatusValue && (
                  <Grid item xs={12} lg={6}>
                    <CustomFormLabel
                      fontWeight={600}
                      variant="h4"
                      textAlign={"center"}
                    >
                      Status
                    </CustomFormLabel>
                    <ControlledCustomSelect
                      fullWidth
                      value={values.status}
                      name="status"
                      onChange={handleChange}
                      placeholder="Select one"
                      displayEmpty
                      options={COMMON_STATUS}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table
                      aria-label="collapsible table"
                      sx={{
                        whiteSpace: {
                          xs: "nowrap",
                          sm: "nowrap",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h4">
                              Section
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={3}>
                            <Typography fontWeight={600} variant="h4">
                              Automobile
                            </Typography>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell />
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Limit
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Deductible
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Premium
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontWeight={600} variant="h6">
                              Occassional Driver Premium
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.coverage_details.map((item, index) => (
                          <React.Fragment key={item.key}>
                            {/* Insert Heading ABOVE "Family Protection Coverage - OPCF 44R" */}
                            {item.label ===
                              "Family Protection Coverage - OPCF 44R" && (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Typography
                                    fontWeight={600}
                                    variant="h5"
                                    sx={{ padding: "8px 0" }}
                                  >
                                    Policy Change Forms (Name & No.)
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            {/* Insert Heading ABOVE "Specified Perils (excluding Collision or Upset)" */}
                            {item.label ===
                              "Specified Perils (excluding Collision or Upset)" && (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Typography
                                    fontWeight={600}
                                    variant="h5"
                                    sx={{ padding: "8px 0" }}
                                  >
                                    LOSS OR DAMAGE
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}

                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell sx={{ minWidth: 600 }}>
                                {!item.isTypeOther ? (
                                  <Typography
                                    sx={{
                                      wordBreak: "break-word",
                                      whiteSpace: "normal",
                                      fontWeight:
                                        item.label ===
                                          "Direct Compensation-Property Damage" ||
                                        item.label === "Uninsured Automobile"
                                          ? 700 // Make these labels bold
                                          : 400,
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                ) : (
                                  <AutoComplete
                                    value={item.label}
                                    options={csioOtherCoverages}
                                    onChange={handleSelectChange(
                                      index,
                                      "label"
                                    )}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <CustomTextField
                                  name="limit"
                                  value={item.limit}
                                  onChange={handleTextChange(index, "limit")}
                                  variant="outlined"
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                <CustomTextField
                                  name="deductible"
                                  value={item.deductible}
                                  onChange={handleTextChange(
                                    index,
                                    "deductible"
                                  )}
                                  variant="outlined"
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                <CustomTextField
                                  name="premimum"
                                  value={item.premimum}
                                  onChange={handleTextChange(index, "premimum")}
                                  variant="outlined"
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>
                                <CustomTextField
                                  name="description"
                                  value={item.description}
                                  onChange={handleTextChange(
                                    index,
                                    "description"
                                  )}
                                  variant="outlined"
                                  size="small"
                                />
                              </TableCell>
                            </TableRow>

                            {/* Insert Subheading if the current row is "Accident Benefits (Standard Benefits)" */}
                            {item.label ===
                              "Accident Benefits (Standard Benefits)" && (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Typography
                                    fontWeight={600}
                                    variant="h5"
                                    sx={{ padding: "8px 0" }}
                                  >
                                    Optional Increased Accident Benefits
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography variant="h4" fontWeight={600}>
                    Total Premium
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h4" fontWeight={600}>
                    $ {total}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h4" fontWeight={600}>
                    $ {totalTwo}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ mt: 4 }}>
                <RoleBasedCustomButton
                  moduleKey={MODULE_IDS.AUTOMOBILE}
                  sx={{ width: "15%" }}
                  disabled={saveLoading}
                  variant="contained"
                  type="submit"
                >
                  Save
                </RoleBasedCustomButton>
              </Box>
            </>
          </FieldSet>
        </form>
      </StandadCard>
    </PageLoader>
  );
};
