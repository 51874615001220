import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../../../../../../components/formsComponents";
import { LabelCard } from "../../../../../../../components/LabelCard/LabelCard";
import { StandardTableActions } from "../../../../../../../components/StandardTableActions/StandardTableActions";
import produce from "immer";
import { IAutomobilePolicyChangeProps } from "./AutomobilePolicyChange.types";
import { AutomobileVehicleInfoRightPanel } from "../../AutomobileVehicle/AutomobileVehicleInformation/AutomobileVehicleInfoRightPanel";
import { IAutomobileVehicleInformation } from "../../../../../../../redux/automobileVehicle/automobileVehicle.types";
import { useAutomobilePolicyVehcileInfo } from "../../hooks/useAutomobileVehicleInfo";
import { useAutomobileCoverageInfo } from "../../hooks/useAutomobileCoverage";
import { AutomobileScheduleARightPanel } from "../../AutomobileSchedule/AutomobileScheduleA/AutomobileScheduleARightPanel";
import { IAutomobileScheduleA } from "../../../../../../../redux/automobileSchedule/automobileSchedule.types";
import { ConfirmDialog } from "../../../../../../../components/Dialogs/ConfirmDialog";
import { FieldSet } from "../../../../../../../components/FieldSet/FieldSet";

export const Coverage: React.FC<IAutomobilePolicyChangeProps> = ({
  values,
  setValues,
  errors,
  handleChange,
  setFieldValue,
  customerId,
  customerPolicyId,
  isStatusCompleted,
  autoSaveData = () => {},
}) => {
  const [open, setOpen] = React.useState<{
    type: "create" | "edit" | "view";
    index?: number;
    data?: IAutomobileScheduleA;
  } | null>(null);
  const theme = useTheme();
  const { coverageDropdown } = useAutomobileCoverageInfo(customerPolicyId);

  const handleCheckBoxChange = (value: string) => {
    setFieldValue("coverage_change.type", value);
  };

  const handleSaveSuccess = (data: IAutomobileScheduleA) => {
    const newValues = produce(values, (draftValues) => {
      const updatedData = {
        ...data,
        customer_policy_id: customerPolicyId ?? null,
        customer_id: customerId ? Number(customerId) : null,
      };
      if (open && open.type === "edit" && open.index !== undefined) {
        draftValues.coverage_change.records[open.index] = updatedData;
      } else {
        draftValues.coverage_change.records.push(updatedData);
      }
    });
    autoSaveData(newValues);
    setValues(newValues);
    setOpen(null);
  };

  const handleCreateEdit = (index?: number) => () => {
    if (isStatusCompleted) {
      return;
    }
    setOpen({
      type: index !== undefined ? "edit" : "create",
      index: index,
      data:
        index !== undefined ? values.coverage_change.records[index] : undefined,
    });
  };

  const handleView = (index: number) => () => {
    // if (isStatusCompleted) {
    //   return;
    // }
    setOpen({
      type: "view",
      index: index,
      data: values.coverage_change.records[index],
    });
  };

  const [deleteConfirm, setDeleteConfirm] = React.useState<number | null>(null);
  const handleDelete = (index: number) => () => {
    setDeleteConfirm(index);
  };

  const handleDeleteConfirm = () => {
    if (deleteConfirm === null) {
      return;
    }
    const newValues = produce(values, (draftValues) => {
      draftValues.coverage_change.records.splice(deleteConfirm, 1);
    });
    setValues(newValues);
    autoSaveData(newValues);
    setDeleteConfirm(null);
  };

  return (
    <>
      <LabelCard heading="Coverage Change" />
      <FieldSet disabled={isStatusCompleted}>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} lg={2.4}>
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={values.coverage_change.type === "Add"}
                onChange={(e) => {
                  handleCheckBoxChange(e.target.checked ? "Add" : "");
                }}
              />
              <Typography>Add</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={values.coverage_change.type === "Delete"}
                onChange={(e) => {
                  handleCheckBoxChange(e.target.checked ? "Delete" : "");
                }}
              />
              <Typography>Delete</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={2.4}>
            <Stack direction={"row"} alignItems={"center"}>
              <Checkbox
                checked={values.coverage_change.type === "Change"}
                onChange={(e) => {
                  handleCheckBoxChange(e.target.checked ? "Change" : "");
                }}
              />
              <Typography>Change</Typography>
            </Stack>
          </Grid>
        </Grid>
      </FieldSet>
      {values.coverage_change.type &&
        ["Add", "Change"].includes(values.coverage_change.type) && (
          <>
            <Grid container spacing={3}>
              {values.coverage_change.type === "Change" && (
                <Grid item xs={12} md={6}>
                  <CustomFormLabel>Delete Coverage</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="coverage_change.delete_coverage"
                    fullWidth
                    displayEmpty
                    value={Number(values.coverage_change.delete_coverage)}
                    onChange={handleChange}
                    placeholder="Select One"
                    options={coverageDropdown}
                  ></ControlledCustomSelect>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"end"}>
                  <Button
                    variant="contained"
                    disabled={isStatusCompleted}
                    onClick={handleCreateEdit()}
                  >
                    Add New
                  </Button>
                </Stack>
                <TableContainer sx={{ width: "30%" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>View</TableCell>
                        <TableCell>#</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.coverage_change.records.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell sx={{ display: "flex" }}>
                              <StandardTableActions
                                onViewClick={handleView(index)}
                                onEditClick={
                                  !isStatusCompleted
                                    ? handleCreateEdit(index)
                                    : undefined
                                }
                                onDeleteClick={
                                  !isStatusCompleted
                                    ? handleDelete(index)
                                    : undefined
                                }
                              />
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}

      {values.coverage_change.type &&
        ["Delete"].includes(values.coverage_change.type) && (
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomFormLabel>Delete Coverage</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="coverage_change.delete_coverage"
                fullWidth
                displayEmpty
                value={Number(values.coverage_change.delete_coverage)}
                onChange={handleChange}
                placeholder="Select One"
                options={coverageDropdown}
              ></ControlledCustomSelect>
            </Grid>
          </Grid>
        )}

      {open && (
        <AutomobileScheduleARightPanel
          open={true}
          readonly={open.type === "view"}
          customerId={customerId}
          customerPolicyId={customerPolicyId}
          onSaveSuccess={handleSaveSuccess}
          staticStatusValue="INACTIVE"
          created_from="Policy_Change"
          onClose={() => setOpen(null)}
          initialData={open.data}
          disableApiCalls
        />
      )}

      {deleteConfirm !== null && (
        <ConfirmDialog
          open
          content="Are you sure you want to delete this record?"
          onClose={() => setDeleteConfirm(null)}
          onConfrim={handleDeleteConfirm}
        />
      )}
    </>
  );
};
