import moment from "moment";
import { LoadState } from "../../constants/enums";
import { formatDate, getUniqueId } from "../../helpers";
import {
  coverageData,
  IAutomobileScheduleA,
  IAutomobileScheduleCoverage,
} from "../automobileSchedule/automobileSchedule.types";
import {
  STANDARD_APP_DATE_FORMAT,
  STANDARD_APP_DATE_TIME_FORMAT,
} from "../../constants/constants";
import { IAutomobileDriverClaims, IAutomobileDriverConviction, IAutomobileDriverInformation } from "../automobileDriver/automobileDriver.types";
import { IAutomobileInterestedParty, IAutomobileVehicleInformation } from "../automobileVehicle/automobileVehicle.types";

export interface IAutomobilePolicyChangeReqState {
  list: IAutomobilePolicyState[];
  policyChangeData: IAutomobilePolicyState;
  totalRecords: number;
  loading: LoadState;
  error: string | null;
}

export interface IAutomobileAdditionalInterest {
  key: string;
  name: string | null;
  code: string | null;
  address: string | null;
  unit_or_suite: string | null;
  city: string | null;
  province_or_state: string | null;
  postal_code: string | null;
  country: string | null;
  nature_of_interest: string | null;
  vehicle_number: string | null;
  interested_party_code: string | null;
}

export interface IAutomobilePolicyState {
  incremental_number: number | null ;
  customer_id: number | null;
  customer_policy_id: number | null;
  customer_name: string | null;
  customer_code: string | null;
  policy_type: string | null;
  branch_code: string | null;
  producer_one_code: string | null;
  policy_status: string | null;
  policy_number: string | null;
  insurer: string | null;
  policy_effective_date: string | null;
  effective_time: string | null;
  expiry_date: string | null;
  type: string | null;
  sub_type: string | null;
  date_change: string | null;
  time_change: string | null;
  date_created: string | null;
  effective_date: string | null;
  days_left: string | null;
  due_date: string | null;
  date_completed: string | null;
  assigned_to_id: string | null;
  current_policy_status: string | null;
  credit_card_info: {
    cc: number | null;
    expiry: string | null;
    cvv: number | null;
    account_holder_name: string | null;
    bank_name: string | null;
    institution_number: string | null;
    transit_number: string | null;
    account_number: string | null;
  };
  name_change: {
    new_name: string | null;
  };
  address_change: {
    address_type: string | null;
    address: string | null;
    address_two: string | null;
    city: string | null;
    state: string | null;
    postal_code: string | null;
    country: string | null;
  };
  driver_change: {
    type: string | null;
    delete_driver: string | null;
    records:IAutomobileDriverInformation[]; //JSON
  };
  driver_conviction_change: {
    type: string | null;
    delete_driver_conviction: string | null;
    records: IAutomobileDriverConviction[]; //JSON
  };
  driver_claim_change: {
    type: string | null;
    delete_driver_claim: string | null;
    records: IAutomobileDriverClaims[]; //JSON
  };
  vehicle_change: {
    type: string | null;
    delete_vehicle: string | null;
    records: IAutomobileVehicleInformation[]; //JSON
  };
  interested_party_change: {
    type: string | null;
    delete_interested_party: string | null;
    records: IAutomobileInterestedParty[]; //JSON
  };
  coverage_change: {
    type: string | null;
    delete_coverage: string | null;
    records:IAutomobileScheduleA[]; //JSON
  };
  attachments: {
    key: string;
    description: string | null;
    status: string | null;
    url: string | null;
    file: File | null;
  }[];
  remarks: string | null;
  status: string;
}

export const initialAutomobilePolicyChangeReqState: IAutomobilePolicyChangeReqState =
  {
    policyChangeData: {
      incremental_number: null,
      customer_id: null,
      customer_policy_id: null,
      customer_name: null,
      customer_code: null,
      policy_type: null,
      branch_code: null,
      producer_one_code: null,
      policy_status: null,
      insurer: null,
      policy_number: null,
      policy_effective_date: null,
      effective_time: null,
      expiry_date: moment().hours(0).minutes(1).format(),
      type: null,
      sub_type: null,
      date_change: moment().format(STANDARD_APP_DATE_FORMAT),
      time_change: moment().format(),
      date_created: moment().format(STANDARD_APP_DATE_FORMAT),
      effective_date: formatDate(moment().format()),
      days_left: null,
      due_date: moment().add(5, "days").format(STANDARD_APP_DATE_FORMAT),
      date_completed: null,
      assigned_to_id: null,
      credit_card_info: {
        cc: null,
        expiry: null,
        cvv: null,
        account_holder_name: null,
        bank_name: null,
        institution_number: null,
        transit_number: null,
        account_number: null,
      },
      name_change: {
        new_name: null,
      },
      address_change: {
        address_type: null,
        address: null,
        address_two: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
      },
      driver_change: {
        type: null,
        delete_driver: null,
        records: [],
      },
      driver_conviction_change: {
        type: null,
        delete_driver_conviction: null,
        records: [],
      },
      driver_claim_change: {
        type: null,
        delete_driver_claim: null,
        records: [],
      },
      vehicle_change: {
        delete_vehicle: null,
        type: null,
        records: [],
      },
      coverage_change: {
        delete_coverage: null,
        type: null,
        records: [],
      },
      interested_party_change: {
        type: null,
        delete_interested_party: null,
        records: [],
      },

      remarks: null,
      attachments: [
        {
          key: getUniqueId(),
          description: null,
          status: "ACTIVE",
          url: null,
          file: null,
        },
      ],
      status: "Active",
      current_policy_status: null
    },
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  };
