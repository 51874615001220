/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { PageLoader } from "../../../../../../components/PageLoader/PageLoader";
import { LoadState, MODULE_IDS } from "../../../../../../constants/enums";
import { StandadCard } from "../../../../../../components/Cards";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDispatchWrapper,
  usePoliciesByCustomer,
} from "../../../../../../hooks";
import { useFormik } from "formik";
import {
  addHabitationalPolicyChange,
  clearHabitationalPolicyChangeReqState,
  fetchHabitationalPolicyChangeReqAsync,
} from "../../../../../../redux/habitationalPolicy/habitationalPolicyChangeRequest/habitationalPolicyChangeReqActions";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../../../components/formsComponents";
import produce from "immer";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../../../../../components/formsComponents/CustomDatePicker";
import {
  formatDate,
  formatDateWithTime,
  renderDropdownValue,
} from "../../../../../../helpers";
import { RoleBasedCustomButton } from "../../../../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

import { UsersAutoSearchByRole } from "../../../../../../components/AutoCompleteSearches/UsersAutoSearchByRole";
import { useCurrentUserId } from "../../../../../../hooks/useAssignedTo";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import {
  addBusinessPolicyChange,
  clearBusinessPolicyChangeState,
  fetchBusinessPolicyChangeAsync,
} from "../../../../../../redux/businessPolicy/businessPolicyChange/businessPolicyChangeActions";
import {
  BusinessPolicyChangeSubTypeOptions,
  task_status,
} from "../../../../../../constants/constants";
import { CreditCardInformation } from "./steps/CrediCardInformation";
import { AddressChangeInformation } from "./steps/AddressChange";
import { PolicyChangeAttachmentUpload } from "./steps/PolicyChangeAttachmentUpload";
import { NameChangeInformation } from "./steps/NameChange";
import { RiskLocationInformation } from "./steps/RiskLocationInformation";
import { InterestedPartyInformation } from "./steps/InterestedPartyInformation";
import { CoverageInformation } from "./steps/CoverageInformation";
import { LiabilityCertificateInformation } from "./steps/LiabilityCertificateInformation";
import {
  IDraftSave,
  useDraft,
  useDraftUrl,
} from "../../../../../../contexts/DraftProvider/DraftProvider";
import { IBusinessPolicyChange } from "../../../../../../redux/businessPolicy/businessPolicyChange/businessPolicyChane.types";
import { DraftStatus } from "../../../../../../contexts/DraftProvider/DraftStatus";
import { FieldSet } from "../../../../../../components/FieldSet/FieldSet";
import { ClaimInformation } from "./steps/ClaimInformation";

export const PolicyChange: React.FC = () => {
  const { customerId, customerPolicyId, incrementalNumber } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
    incrementalNumber?: number;
  };

  const isUpdate = incrementalNumber ? true : false;
  const { policyChangeData, loading } = useSelector(
    (storeState: IStoreState) => storeState.business.policyChange
  );

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const { fetchPolicyOtherInfoByPolicyId, fetchPoliciesByCustomerPolicyId } = usePoliciesByCustomer();

  const currentUserId = useCurrentUserId();
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const { saveAutoDraft, removeAutoSavedDraft } = useDraft();
  const draftUrl = useDraftUrl();
  const location = useLocation();
  const isCompletedRef = React.useRef(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: policyChangeData,
    validate: (values) => {},
    onSubmit: (values) => {
      if (customerId && customerPolicyId) {
        dispatch(
          addBusinessPolicyChange(
            {
              ...values,
              customer_id: customerId,
              customer_policy_id: customerPolicyId,
            },
            (isSuccess, data) => {
              if (isSuccess && data) {
                removeAutoSavedDraft(draftUrl);
                if (buttonClicked && buttonClicked === "Continue") {
                  navigate(
                    `/business-policy/${customerId}/policy-change-request/${customerPolicyId}/manage/${data.incremental_number}`,
                    { replace: true }
                  );
                } else {
                  navigate(
                    `/business-policy/${customerId}/policy-change-request-list/${customerPolicyId}`
                  );
                }
              }
            }
          )
        );
      }
    },
  });

  const handleAutoSave = (finalValues: IBusinessPolicyChange) => {
    if (incrementalNumber) {
      saveAutoDraft({
        draft_code: null,
        form_url: draftUrl,
        reference_data: finalValues,
        reference_data_id: incrementalNumber,
        status: "ACTIVE",
      });
    }
  };

  const handleTypeChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.type = value;
      draftValues.sub_type = null;
      draftValues.address_change = {
        address: null,
        address_two: null,
        address_type: null,
        city: null,
        country: null,
        postal_code: null,
        state: null,
      };
    });
    setValues(newValues);
  };

  const handleSubTypeChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    const newValues = produce(values, (draftValues) => {
      draftValues.sub_type = value;
      draftValues.name_change = {
        new_name: null,
      };
      draftValues.address_change = {
        address: null,
        address_two: null,
        address_type: null,
        city: null,
        country: null,
        postal_code: null,
        state: null,
      };
    });
    setValues(newValues);
  };

  const handleDraftSelect = (data: IDraftSave) => {
    setValues(data.reference_data);
  };

  React.useEffect(() => {
    (async () => {
      if (isUpdate) {
        if (policyChangeData.status === "Completed") {
          isCompletedRef.current = true;
        }
        setValues(policyChangeData);
      } else {
        if (customerId && customerPolicyId) {
          const policyOtherInfo = await fetchPoliciesByCustomerPolicyId(customerPolicyId);
          const policyInfo = Array.isArray(policyOtherInfo) ? policyOtherInfo[0] : null; 
          if (policyInfo) {
            const newValues = produce(values, (draftValues) => {
              draftValues.customer_id = customerId;
              draftValues.customer_policy_id = customerPolicyId;
              draftValues.policy_number = policyInfo.policy_number;
              draftValues.customer_name = policyInfo.customer_name;
              draftValues.customer_code = policyInfo.customer_code;
              draftValues.policy_type = policyInfo.policy_type;
              draftValues.branch_code = policyInfo.branch_code;
              draftValues.producer_one_code =
                policyInfo.producer_one_code || "--";
              draftValues.policy_status = policyInfo.policy_status;
              draftValues.insurer = policyInfo.insurer;
              draftValues.policy_effective_date = formatDate(
                policyInfo.effective_date
              );
              draftValues.expiry_date = policyInfo.expiry_date;
              draftValues.assigned_to_id = currentUserId as any;
              draftValues.current_policy_status = policyInfo.current_policy_status
            });
            setValues(newValues);
          }
        }
      }
    })();
  }, [policyChangeData]);

  React.useEffect(() => {
    if (customerPolicyId && incrementalNumber) {
      dispatch(
        fetchBusinessPolicyChangeAsync(customerPolicyId, incrementalNumber)
      );
    }
  }, [incrementalNumber]);

  React.useEffect(() => {
    return () => {
      dispatch(clearBusinessPolicyChangeState());
    };
  }, []);

  return (
    <PageLoader loading={loading === LoadState.InProgress}>
      <PageContainer
        title="View Policy Change Req"
        description="this is innerpage"
      >
        <StandadCard
          sx={{ mt: 2 }}
          heading="Business Policy Change Req Form"
          rightHeading={
            <DraftStatus
              referenceId={draftUrl}
              onSelect={handleDraftSelect}
            />
          }
        >
          <form onSubmit={handleSubmit}>
            <FieldSet disabled={isCompletedRef.current}>
              <Grid container spacing={2} mb={5}>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Customer Name
                    </Typography>
                    <Typography>{values.customer_name}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Customer Code
                    </Typography>
                    <Typography>{values.customer_code}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Policy
                    </Typography>
                    <Typography>{values.policy_type}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Branch Code
                    </Typography>
                    <Typography>{values.branch_code}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Producer 1
                    </Typography>
                    <Typography>{values.producer_one_code}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Policy Status
                    </Typography>
                    <Typography>{values.current_policy_status}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Policy Number
                    </Typography>
                    <Typography>{values.policy_number}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Insurer Code
                    </Typography>
                    <Typography>{values.insurer}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Effective Date
                    </Typography>
                    <Typography>
                      {formatDate(values.policy_effective_date)}
                    </Typography>
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Effective Time
                    </Typography>
                    <Typography>
                      {formatDateWithTime(values.effective_time)}
                    </Typography>
                  </Stack>
                </Grid> */}
                <Grid item xs={12} md={2.4}>
                  <Stack display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      variant="body1"
                      fontSize={"1rem"}
                      fontWeight={600}
                    >
                      Expiry Date
                    </Typography>
                    <Typography>{formatDate(values.expiry_date)}</Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }} />

              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Type</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    fullWidth
                    displayEmpty
                    name="type"
                    value={values.type}
                    onChange={handleTypeChange}
                    placeholder="Select One"
                    options={[
                      { label: "General Change", value: "GeneralChange" },
                      { label: "New", value: "New" },
                      { label: "Policy Change", value: "PolicyChange" },
                      {
                        label: "Payment Method Change",
                        value: "PaymentMethod",
                      },
                      {
                        label: "Liability Insurance",
                        value: "LiabilityInsurance",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomFormLabel>Subtype</CustomFormLabel>
                  <ControlledCustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    fullWidth
                    displayEmpty
                    name="sub_type"
                    value={values.sub_type}
                    onChange={handleSubTypeChange}
                    placeholder="Select One"
                    options={
                      BusinessPolicyChangeSubTypeOptions[
                        values.type as keyof typeof BusinessPolicyChangeSubTypeOptions
                      ] || []
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Date change</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled
                    value={values.date_change || ""}
                    onChange={(newValue) => {
                      setFieldValue("date_change", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomFormLabel>Time Change</CustomFormLabel>
                  <CustomTimePicker
                    fullWidth
                    value={values.time_change}
                    onChange={(newValue) => {
                      setFieldValue("time_change", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <CustomFormLabel>Date Created</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled
                    value={values.date_created || ""}
                    onChange={(newValue, withTime) => {
                      setFieldValue("date_created", withTime);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <CustomFormLabel>Effective Date</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    value={values.effective_date || ""}
                    onChange={(newValue) => {
                      setFieldValue("effective_date", newValue);
                    }}
                    errorMessage={errors.effective_date}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <CustomFormLabel>Days Left</CustomFormLabel>
                  <CustomTextField
                    fullWidth
                    id="days_left"
                    name="days_left"
                    value={values.days_left || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={1.9}>
                  <CustomFormLabel>Due Date</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    value={values.due_date || ""}
                    onChange={(newValue) => {
                      setFieldValue("due_date", newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={1.9}>
                  <CustomFormLabel>Date Completed</CustomFormLabel>
                  <CustomDatePicker
                    fullWidth
                    disabled
                    value={values.date_completed || ""}
                    onChange={(newValue) => {
                      setFieldValue("date_completed", newValue);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={2.4}>
                  <UsersAutoSearchByRole
                    label="Assigned To"
                    value={values.assigned_to_id}
                    errorMessage={errors.assigned_to_id}
                    onSelect={(data) => {
                      setFieldValue("assigned_to_id", data.user_fact_id);
                    }}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mt: 3, mb: 1 }} />
            </FieldSet>

            {!isUpdate && (
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => setButtonClicked("Continue")}
                >
                  Continue
                </Button>
              </Box>
            )}

            {isUpdate && (
              <>
                <FieldSet disabled={isCompletedRef.current}>

                {values.sub_type &&
                  [
                    "Payment Method Change",
                    "General Change",
                    "Home (Upload)",
                    "Home (Remarket)",
                  ].includes(values.sub_type) && (
                    <>
                      <CreditCardInformation
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        isStatusCompleted={isCompletedRef.current}
                      />
                    </>
                  )}

                {values.sub_type && values.sub_type === "Name Change" && (
                  <NameChangeInformation
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    handleChange={handleChange}
                    isStatusCompleted={isCompletedRef.current}
                  />
                )}

                {values.sub_type && values.sub_type === "Address Change" && (
                  <AddressChangeInformation
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    handleChange={handleChange}
                    isStatusCompleted={isCompletedRef.current}
                  />
                )}
                

                {values.sub_type &&
                  values.sub_type === "Risk Location Change" && (
                    <>
                      <RiskLocationInformation
                        values={values}
                        errors={errors}
                        customerPolicyId={customerPolicyId}
                        customerId={customerId}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        handleChange={handleChange}
                        autoSaveData={handleAutoSave}
                        isStatusCompleted={isCompletedRef.current}
                      />
                      <InterestedPartyInformation
                        values={values}
                        errors={errors}
                        customerPolicyId={customerPolicyId}
                        customerId={customerId}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        handleChange={handleChange}
                        autoSaveData={handleAutoSave}
                        isStatusCompleted={isCompletedRef.current}
                      />

                      <CoverageInformation
                        values={values}
                        errors={errors}
                        customerPolicyId={customerPolicyId}
                        customerId={customerId}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        handleChange={handleChange}
                        autoSaveData={handleAutoSave}
                        isStatusCompleted={isCompletedRef.current}
                      />
                    </>
                  )}

                {values.sub_type &&
                  values.sub_type === "Interested Party Change" && (
                    <>
                      <InterestedPartyInformation
                        values={values}
                        errors={errors}
                        customerPolicyId={customerPolicyId}
                        customerId={customerId}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        handleChange={handleChange}
                        autoSaveData={handleAutoSave}
                        isStatusCompleted={isCompletedRef.current}
                      />
                    </>
                  )}

                {/* {values.sub_type && values.sub_type === "Coverage Change" && (
                  <>
                    <CoverageInformation
                      values={values}
                      errors={errors}
                      customerPolicyId={customerPolicyId}
                      customerId={customerId}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      handleChange={handleChange}
                      autoSaveData={handleAutoSave}
                      isStatusCompleted={isCompletedRef.current}
                    />
                  </>
                )} */}

                

                {values.sub_type &&
                  values.sub_type === "Liability Insurance" && (
                    <>
                      <LiabilityCertificateInformation
                        values={values}
                        errors={errors}
                        customerPolicyId={customerPolicyId}
                        customerId={customerId}
                        setFieldValue={setFieldValue}
                        setValues={setValues}
                        handleChange={handleChange}
                        autoSaveData={handleAutoSave}
                        isStatusCompleted={isCompletedRef.current}
                      />
                    </>
                  )}

                <>
                  <PolicyChangeAttachmentUpload
                    values={values}
                    errors={errors}
                    customerPolicyId={customerPolicyId}
                    customerId={customerId}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    handleChange={handleChange}
                    isStatusCompleted={isCompletedRef.current}
                  />
                </>
                <Grid container>
                  <Grid item xs={12}>
                    <CustomFormLabel>Remarks</CustomFormLabel>
                    <CustomTextField
                      name="remarks"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={3}
                      type="text"
                      fullWidth
                      value={values.remarks}
                      onChange={handleChange}
                    />
                    <Grid item xs={12} md={6}>
                      <CustomFormLabel>Status</CustomFormLabel>
                      <CustomSelect
                        variant="outlined"
                        size="small"
                        type="string"
                        name="status"
                        fullWidth
                        value={values.status}
                        onChange={handleChange}
                        displayEmpty
                        renderValue={renderDropdownValue(`Select One`)}
                      >
                        {task_status.map((STab) => {
                          return <MenuItem value={STab}>{STab}</MenuItem>;
                        })}
                      </CustomSelect>
                    </Grid>
                  </Grid>
                </Grid>

                <Stack direction={"row"} spacing={2} marginTop={3}>
                  <RoleBasedCustomButton
                    moduleKey={MODULE_IDS.HABITATIONAL}
                    type="submit"
                    variant="contained"
                    disabled={isCompletedRef.current}
                    onClick={()=> setButtonClicked("save")}
                  >
                    Save
                  </RoleBasedCustomButton>
                </Stack>
                </FieldSet>
              </>
            )}
          </form>
        </StandadCard>
      </PageContainer>
    </PageLoader>
  );
};
