import React from "react";
import { Box, Button, Link, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatchWrapper } from "../../../../../../hooks";
import { IStoreState } from "../../../../../../redux/initialStoreState";
import { useSelector } from "react-redux";
import { LoadState } from "../../../../../../constants/enums";
import { useTableV2State } from "../../../../../../components/TableV2/hooks/useTableV2State";
import { fetchHabitationalPolicyChangeListAsync } from "../../../../../../redux/habitationalPolicy/habitationalPolicyChangeRequest/habitationalPolicyChangeReqActions";
import { ControlledCustomSelect } from "../../../../../../components/formsComponents";
import {
  DataTableV2RowRenderType,
  IDataTableV2Props,
} from "../../../../../../components/TableV2/interfaces/IDataTableV2Props";
import { IHabitationalPolicyChange } from "../../../../../../redux/habitationalPolicy/habitationalPolicyChangeRequest/habitationalPolicyChangeReq.types";
import { StatusRenderer } from "../../../../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { DataTableV2 } from "../../../../../../components/TableV2/DataTableV2";
import { task_status } from "../../../../../../constants/constants";
import { PageTitleBar } from "../../../../../../components/PageTitleBar/PageTitleBar";
import { PageContainer } from "../../../../../../components/container/PageContainer";
import { StandardTableActions } from "../../../../../../components/StandardTableActions/StandardTableActions";
import { AddOutlined } from "@mui/icons-material";

export const HabitationalPolicyChangeList: React.FC = (props) => {
  const { customerId, customerPolicyId } = useParams() as {
    customerId?: number;
    customerPolicyId?: number;
  };
  const navigate = useNavigate();
  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.habitational.policyChangeReq
  );
  const dispatch = useDispatchWrapper();
  const {
    state: { dateState, searchState, tabs, columnsConfig },
    setDateState,
    setSelectedTab,
    setTableTabs,
    setSearchState,
    setColumnVisibility,
  } = useTableV2State({
    filtersInitialState: {
      defaultDateRange: "last28Days",
      selectedTab: "-1",
    },
  });
  const [status, setStatus] = React.useState<string>("-1");

  const handleCreate = () => {
    navigate(
      `/habitational-policy/${customerId}/policy-change-request/${customerPolicyId}`
    );
  };

  const fetchList = () => {
    if (customerPolicyId) {
      dispatch(
        fetchHabitationalPolicyChangeListAsync(customerPolicyId, status)
      );
    }
  };

  const policyChangeTableProps: IDataTableV2Props = {
    isPagination: false,
    isDataLoading: loading === LoadState.InProgress,
    totalRecords: totalRecords,
    tableCommandBarProps: {
      preDefinedPlugins: {
        columnVisibility: {
          columnVisibility: columnsConfig.columnVisibility,
          onChange: setColumnVisibility,
        },
        refresh: {
          onClick: fetchList,
        },
      },
      leftItems: {},
      rightItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <ControlledCustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e) => setStatus(e.target.value as "ACTIVE")}
                options={[
                  { label: "All", value: "-1" },
                  ...task_status.map((x) => ({ label: x, value: x })),
                ]}
              ></ControlledCustomSelect>
            ),
          },
        ],
      },
    },
    masterColumns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        width: "100px",
        disableResize: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IHabitationalPolicyChange) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-change-request/${customerPolicyId}/manage/${row.incremental_number}`
                );
              }}
            />
          );
        },
      },
      {
        key: "incremental_number",
        headerName: "#",
        fieldName: "incremental_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 15,
        enableSorting: true,
        onRowCellRender: (value, row: IHabitationalPolicyChange) => {
          return (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/habitational-policy/${customerId}/policy-change-request/${customerPolicyId}/manage/${row.incremental_number}`
                );
              }}
            >
              {row.incremental_number}
            </Link>
          );
        },
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        enableSorting: true,
        renderType: DataTableV2RowRenderType.CUSTOM_RENDER,
        // exportCellWidth: 25,
        onRowCellRender: (values, row: IHabitationalPolicyChange) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "customer_name",
        headerName: "Customer Name",
        fieldName: "customer_name",
        renderType: DataTableV2RowRenderType.TEXT_DARK,
        // exportCellWidth: 20,
        enableSorting: true,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "policy_type",
        headerName: "Line of Business",
        fieldName: "policy_type",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "type",
        headerName: "Type",
        fieldName: "type",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (values, row: IHabitationalPolicyChange) => {
          return row.type === "PolicyChange" ? "Policy Change" : row.type;
        },
      },
      {
        key: "sub_type",
        headerName: "Sub Type",
        fieldName: "sub_type",
        renderType: DataTableV2RowRenderType.TEXT,
        // exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "branch_code",
        headerName: "Branch Code",
        fieldName: "branch_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "producer_one_code",
        headerName: "Producer Code",
        fieldName: "producer_one_code",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "policy_status",
        headerName: "Policy Status",
        fieldName: "policy_status",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "policy_number",
        headerName: "Policy Number",
        fieldName: "policy_number",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "insurer",
        headerName: "Insurer",
        fieldName: "insurer",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "policy_effective_date",
        headerName: "Policy Effective Date",
        fieldName: "policy_effective_date",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "effective_time",
        headerName: "Effective Time",
        fieldName: "effective_time",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "expiry_date",
        headerName: "Expiry Date",
        fieldName: "expiry_date",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },

      {
        key: "date_change",
        headerName: "Date Change",
        fieldName: "date_change",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "time_change",
        headerName: "Time Change",
        fieldName: "time_change",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "date_created",
        headerName: "Date Created",
        fieldName: "date_created",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "days_left",
        headerName: "Days Left",
        fieldName: "days_left",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "due_date",
        headerName: "Due Date",
        fieldName: "due_date",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "date_completed",
        headerName: "Date Completed",
        fieldName: "date_completed",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.DATE_TIME,
      },
      {
        key: "assigned_to_id",
        headerName: "Assigned To Id",
        fieldName: "assigned_to_id",
        // exportCellWidth: 25,
        renderType: DataTableV2RowRenderType.TEXT_DARK,
      },
      {
        key: "insert_ts",
        headerName: "Date Modified",
        fieldName: "insert_ts",
        renderType: DataTableV2RowRenderType.DATE_TIME_DARK_COLOR,
      },
    ],
    // tableTabProps: {
    //   selectedTab: tabs.selectedTab,
    //   tabs: tabs.tabs,
    //   onTabChange: (newSelectedTab) => {
    //     setSelectedTab(newSelectedTab);
    //   },
    // },
    rows: list,
  };

  React.useEffect(() => {
    fetchList();
  }, [status]);

  return (
    <PageContainer
      title="Policy Change"
      description="this is Policy Change Request list"
    >
      <PageTitleBar
        rightHeading={
          <Button
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlined />}
          >
            Create
          </Button>
        }
      />
      <DataTableV2 {...policyChangeTableProps} />
    </PageContainer>
  );
};
